import * as React from "react"
import Wrapper from "../components/wrapper"
import Header from "../components/header"
import Footer from "../components/footer"

const SocialPage = () => {
  return (
    <Wrapper title="Follow - Dylan Feltus" description="Follow me on the internets as I build in public." path="/social">
      <Header title="Follow me on social" className="bg-gray-50" />
      <div className="container">
        <p className="mt-20 mb-4 text-base text-gray-800">Every now and then I post updates on my work here.</p>
        <p className="mb-12 text-xs italic text-gray-600">** This page is a WIP and will eventually become a blog. More soon...</p>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-x-5 gap-y-2 mb-32 max-w-2xl">
          <SocialProfile name="Twitter" url="https://twitter.com/dylanfeltus" color="#1DA1F2" />
          <SocialProfile name="Instagram" url="https://instagram.com/dylanfeltus" color="#5851DB" />
          <SocialProfile name="Dribbble" url="https://dribbble.com/dylanfeltus" color="#EA4C89" />
          <SocialProfile name="Figma" url="https://figma.com/@dylanfeltus" color="#08CF82" />
        </div>
      </div>
      <Footer />
    </Wrapper>
  )
}

const SocialProfile = ({ name, url, color }) => {

  return(
    <a href={url} className="flex items-center font-default no-underline hover:bg-gray-50 text-gray-800 hover:text-black rounded px-4 py-2 transition ease-in-out duration-200" target="_blank">
      <div className="w-3 h-3 rounded-full bg-gray-500 mr-2" style={{ backgroundColor: color }}></div>
      <p>{name}</p>
    </a>
  )
}

export default SocialPage
